<template>
  <div class="flex h-screen flex-col">
    <div class="m-auto">
      <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
        <client-only>
          <div v-if="isLoading"><full-screen-loading /></div>
          <div v-else>
            <slot></slot>
          </div>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useTokenStore } from "~/stores/token";

const tokenStore = useTokenStore();
const { isLoading } = storeToRefs(tokenStore);
</script>
